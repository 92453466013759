

.wrapperLogin {
width: 430px;
background:transparent;
/* border: 2px solid rgb(255,255,255,.2); */
backdrop-filter: blur(7px);
/* box-shadow: 0,0 10px,rgb(255,255,255,.2); */
color:white;
border-radius: 10px;
padding: 30px 15px;
}
.wrapperLogin h1{
    font-size: 28px;
    text-align: center;
    color:rgb(255, 255, 255);
}
.css-1d3z3hw-MuiOutlinedInput-notchedOutline{
    border: 1px solid #fff !important;
    border-radius: 20px !important;
}

.wrapperLogin .remember-forget{
    display: flex;
    justify-content: space-between;
    font-size: 14.5px;
    margin: -15px 0 15px;
    padding: 10px;
}
.remember-forget a{
    color:white;
    
}
img.logo{
    height: 90px;
    padding: 0;
}
label{
    color: #fff !important;
}

.wrapperLogin .register-link{
 
font-size:12px;
text-align: center;
margin-top: 30px;

} 

.CustomLink{
    font-family: 'Courier New', Courier, monospace;
    font-style: oblique;
    font-size: 12px;
    color: green;
}
.register-link p a{
    color:white;
    text-decoration: none;
    font-weight: 600;

}
.register-link p a:hover{

    text-decoration: underline;
}

.mycenter {
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn.btn-submit{
    background-color: rgb(231 67 63) !important;
    color: #fff !important;
    border-radius: 20px;
    padding: 10px 30px;
}

.recaptcha .rc-anchor-checkbox-label {
    font-family: Roboto, helvetica, arial, sans-serif;
    font-size: 4px;
    font-weight: 400;
    line-height: 17px;
}
/* .rc-anchor-normal{
    border: 1px solid #c41010;
} */
.recaptcha{
    color:aqua;
}
.css-i4bv87-MuiSvgIcon-root{
    color: #cdcdcd;
}
.css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input, .css-19qh8xo-MuiInputBase-input-MuiOutlinedInput-input{
    color: #fff !important;
}

.bodyLogin {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background:url('../../../public/images/friends-cooking.jpg') 
    no-repeat;
    background-size: cover;
    background-position: center;
  
  }


  .design{
    padding-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;
    /* font-family: 'Courier New', Courier, monospace;
    font-style: normal;
    font-size: 12px;
    font-weight: 100; */
  }
  .rc-anchor-light {
    background: #8080802b !important;
    color: #fffcfc !important;
}
.rc-anchor-light .rc-anchor-logo-text, .rc-anchor-light div a:link, .rc-anchor-light div a:visited{
    color: #fff !important;
}