.wrapper1{
    width: 440px;
    background:wheat;
    border-radius: 10px;
    padding: 10px 10px;
    margin-bottom: 10px;
}
.wrapper3{
    padding-bottom: 10px;
}
.wrapper2{
    display: flex;
    justify-content: center;
    padding-left: 100px;
    padding-top: 10px;
    /* width: 100rlh;
    flex-direction: column;
    align-items: center;
    padding-left: 0px;
    padding-top: 20px; */
}
.wrapper1 h1{
    width: 440px;
    font-size: 36px;
    text-align: center;
  }