.design{
    padding-bottom: 20px;
}
.design-top{
    padding-top: 30px;
}

.designHeader{
    margin-top: 20px;
    padding-bottom: 25px;
}
